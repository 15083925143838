/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; overflow-x: hidden; overflow-y: hidden;}
body { margin: 0px; font-family: Roboto, "Helvetica Neue", 
    sans-serif; overflow: hidden;}

/* mat-confirm-dialog (customised dialog) */
.confirm-dialog-container .mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 0px;
}
.confirm-dialog-container span.content-span {
    padding: 35px 16px;
    text-align: center;
    font-size: 20px;
}
.confirm-dialog-container .content-container{
    margin: 5px 5px 15px 5px;
    color: #8f9cb5;
    display: flex;
}
.confirm-dialog-container #close-icon{
    margin-left: auto;
    order: 2;
    font-weight: bolder;
}
.confirm-dialog-container #close-icon:hover{
    cursor: pointer;
}

.confirm-dialog-container #no-button{
    height: 50px;
    width: 50%;
    background-color: #fc7169;
    color:white;
    border-radius: 0px;
}
.confirm-dialog-container #yes-button{
    height: 50px;
    width: 50%;
    background-color: #b6bece;
    color:white;
    border-radius: 0px;
}
.confirm-dialog-container #error-button{
    height: 50px;
    width: 100%;
    background-color: #ac0b0b;
    color:white;
    border-radius: 0px;
}
.confirm-dialog-container #success-button{
    height: 50px;
    width: 100%;
    background-color: #07912a;
    color:white;
    border-radius: 0px;
}
/* mat-confirm-dialog (customised dialog - end) */

.mat-table-header-color, .mat-header-cell {
    background-color:DodgerBlue;;
      color:white;
      height: 8vh;
  }
  .mat-drawer-container {
    background-color: rgb(236, 237, 243); 
    color: rgba(0,0,0,.87);
}
  .button-list-add, .button-list-download, .button-list-upload , .button-list-directory {
      border: none;
      color: white;
      padding-top: 5px;
      padding-bottom: 0px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      border-radius: 3%;

  }
  .button-list-add{
      background-color: rgb(8, 117, 12); /* Green */
  }
  .button-list-download{
      background-color: rgb(192, 22, 115); /* Green */
       
  }
  .button-list-upload{
    background-color: rgb(61, 15, 189); /* Green */
    height: 35px;
    width: 35px;
}
.button-list-directory{
    background-color: #e8ec12fa; /* Green */
    height: 35px;
    width: 35px;
}
  .button-list-add:hover {
      background-color: rgb(10, 161, 68);
      color: black;
  }
  .button-list-download:hover {
      background-color: rgb(168, 90, 149);
      color: rgb(241, 232, 232);
  }
  .button-list-directory:hover {
    background-color: #e8ec12fa;;
    color: rgb(241, 232, 232);
}
  .container-dialog {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .container-dialog > * {
    width: 100%;
  }
  .mat-header-cell {
      font-size: 12px;
  }
  .mat-cell {
    font-size: small;
}
 .mat-dialog-title {
   padding: 15px 18px;
   text-align: center;
   background-color: rgb(52, 102, 241);
   box-sizing: border-box;
   color:white;
   font-weight: lighter;
   font-size: 18px;
  }

  .dialog-button {
    background-color: rgb(4,180,150);
    color:white;
  }
  .dialog-cancel-button {
    background-color: rgb(151, 50, 50);
    color:white;
  }
  .customWidthClassId{
    flex: 0 0 100px;
    background-color:white;
 }
 .customWidthClassIdHead{
    flex: 0 0 100px;
 }
 input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
}

.title-color {
    background-color: rgb(252, 67, 144);
    box-sizing: border-box;
    color: rgb(239, 245, 242);
    font-weight: lighter;
    font-size: 18px;
}

.myapp-no-padding-dialog .mat-dialog-container {

    flex: 80%;
  }
  .mat-dialog-container {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-y: hidden !important;
}

@media print {
    .isPrinting > * { display: none; }
    .isPrinting app-print-layout { display: block; }
  }
  
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(17, 44, 163);
    color: white;
    text-align: center;
  }

  .loading-indicator {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading-indicator:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
  .mat-grid-tile .mat-figure {
    justify-content: flex-start !important ;
    align-items: flex-start !important;
 }

 .button-list-add, .button-list-download, .button-list-upload , .button-list-directory {
  border: none;
  color: white;
  padding-top: 5px;
  padding-bottom: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 3%;
  height: 45px;

}
.button-list-add{
  background-color: rgb(8, 117, 12); /* Green */
}
.button-list-download{
  background-color: rgb(192, 22, 115); /* Green */
   
}
.button-list-upload{
  background-color: rgb(61, 15, 189); /* Green */
  height: 35px;
  width: 35px;
}
.button-list-directory{
background-color: #e8ec12fa; /* Green */
height: 35px;
width: 35px;
}
.button-list-add:hover {
  background-color: rgb(10, 161, 68);
  color: black;
}
.button-list-download:hover {
  background-color: rgb(168, 90, 149);
  color: rgb(241, 232, 232);
}
.button-list-directory:hover {
background-color: #e8ec12fa;;
color: rgb(241, 232, 232);
}

::ng-deep .mat-form-field-wrapper{
  margin-bottom: -1.25em;
}

.mat-tab-label-active 
{
    color: #00ADEE !important;
} 

.mat-tab-label-active 
{
    color: #00ADEE !important;
} 
.remove-border-bottom .mat-tab-header 
{
    border-bottom: none;
}

.mat-tab-nav-bar:active
{
    color: #00ADEE !important;
}

.mat-ink-bar{
    background-color: #00ADEE !important;
    display:block;
    height: 8px !important;
}